<template>

  <div>
   <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      <div v-if="status == 'ENDED'"><br><br>
      <center>
      <v-card outlined height="150%" width="98%">
          <span style="font-size:30px;font-weight:bold;"> Feedback is Closed. Please Contact the Respective Organization</span>
      </v-card>
      </center>
      
      </div>
      <div v-else-if="status == 'TO_Start'"><br><br>
      <center>
      <v-card outlined height="150%" width="98%">
          <span style="font-size:30px;font-weight:bold;"> Feedback is Yet to Start. Please Contact the Respective Organization</span>
      </v-card>
      </center>
      
      </div>
      <div v-else>
      <v-row>
        <v-col align="center">
        <h1>Employer Feedback</h1><br>

        {{status}}
         <table style="width:50%">
            <tr>
              <th>Feedback Type</th>
              <th>Academic Year</th> 
              <th>Semester</th>
            </tr>
            <tr>
              <td>{{feedbacktype}}</td>
              <td>{{ay}}</td>
              <td>{{sem}}</td>
            </tr>
            <tr>
              <th>Feedback Number</th>
              <th>Start Date</th> 
              <th>End date</th>
            </tr>
            <tr>
              <td>{{feedbacknumber}}</td>
              <td>{{startdate}}</td>
              <td>{{enddate}}</td>
            </tr>
          </table>
          </v-col>
      </v-row>

        <hr  style="height:2px;border-width:0;color:gray;background-color:gray ;width:97%;margin-left:15px">
        <v-row cols="12">
        <v-col cols="8">
        <span style="font-size:20px;" v-if="allanswered =='YES'">
             <center><strong><span class="blink" style="font-size:26px;font-weight:bold;color:green;"> Feedback is Complete . Thank You.</span></strong></center>
           </span>
          <v-card style="margin-left:15px;padding-left:20px;" height="150%" width="98%" outlined>
           <span>
            <span id="change">
            <v-card-title style="background-color:#BBDEF">Question <v-spacer></v-spacer> {{qno+1}}/{{questionlist.length}}</v-card-title>
               <v-row style="margin-left:20px;" >{{qno+1}}.&nbsp;&nbsp; {{questionlist[qno].question}}</v-row><br>
                
                <v-row  v-if="questionlist[qno].is_multiplechoice == true" max-width="555">
               
                                    <v-col cols="11" sm="11" class="mx-auto" style="margin-top:-30px">
                                     <h4>Options : </h4>
                                    <v-row>
                                        <v-col cols="3" sm="3" v-for="(role, index1) in questionlist[qno].options" :key="index1">
                                            <v-checkbox v-if=" role.isSelectedOP == 'true'"
                                              :label="role.option"
                                              checked
                                              v-model="role.isSelectedOP"
                                              ></v-checkbox>
                                               <v-checkbox v-else
                                              :label="role.option"
                                              color="success"
                                              v-model="role.isSelectedOP"
                                              ></v-checkbox>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>

                                  <v-row v-else-if=" questionlist[qno].is_choiceapplicable == true " max-width="555">
                                 
                                    <v-col cols="11" sm="11" class="mx-auto" style="margin-top:-30px">
                                     <h4 >Options : </h4>
                                    <v-row>
                                
                                        <v-col cols="10" sm="9" v-if="questionlist[qno].singleanswer == ''">
                                            <v-radio-group row v-model="questionlist[qno].singleanswer">
                                                <v-radio
                                                  v-for="n in questionlist[qno].options"
                                                  :key="n"
                                                  :label="`${n.option}`"
                                                  :value="n"  
                                                  @click="markedanswer(n,qno),changeQuestion(qno+1)"                                             
                                                ></v-radio>
                                              </v-radio-group>
                                        </v-col>
                                        <v-col cols="10" sm="9" v-else>
                                       
                                               <v-radio-group row v-model="questionlist[qno].singleanswer.option_id">
                                                <v-radio 
                                                  v-for="n in questionlist[qno].options"
                                                  :key="n"
                                                  :label="`${n.option}`"
                                                  :value="n.option_id"
                                                  @click="markedanswer(n,qno),changeQuestion(qno+1)"
                                                ></v-radio>
                                              </v-radio-group>              
                                         </v-col>                                       
                                      </v-row>
                                    </v-col>
                                  </v-row>

                                  <v-row v-else max-width="555">
                                    <v-col cols="11" sm="11" class="mx-auto" style="margin-top:-30px">
                                    <v-row>                                       
                                            <v-col cols="10" sm="10" class="mx-auto" >
                                              <v-textarea   outlined v-model="questionlist[qno].descrAnswer"
                                                placeholder="Enter Your Feedback"
                                                clearable
                                                required>
                                              </v-textarea>
                                            </v-col>                                       
                                      </v-row>
                                       <v-row v-if="islastquestion == false">  
                                        <v-col cols="5" sm="5" ></v-col>                                     
                                                 <v-btn  color="success" dark class="mb-2"  @click="markedanswer(questionlist[qno].descrAnswer,qno),changeQuestion(qno+1)">
                                                    SAVE
                                                </v-btn>                           
                                      </v-row>
                                    </v-col>
                                  </v-row>
            </span> 

             <center class="brmedium">
                            <v-btn v-if="islastquestion == true" color="primary" dark class="mb-2"  @click="save(questionlist)">
                                SUBMIT FEEDBACK
                            </v-btn>&nbsp;
                            <!-- <v-btn dark class="mb-2" style="background:yellow;color:black;"  @click="save('R', qno)">
                                NEXT
                            </v-btn> -->
                        </center>
        
           </span>
          </v-card>
        </v-col>
        <v-col cols="4">
        <v-card style="margin-left:-8px;;padding-left:20px;" height="150%" width="98%">
        <v-card-title style="background-color:#BBDEF">Questions Overview</v-card-title>
            <span v-for="(item, index) in questionlist" :key="index">
                                        <div style="display:inline;">
                                            &nbsp;
                                            <v-btn v-if="item.feedback_present == 'YES'" dark class="butn mb-2" style="background:green;color:black;"  @click="changeQuestion(index)">
                                                {{index+1}}
                                            </v-btn> 
                                            <v-btn v-else dark class="butn mb-2" style="background:red;color:black;"  @click="changeQuestion(index)">
                                                {{index+1}}
                                            </v-btn>                                                                                                       
                                        </div>
                                </span>
                                  <span><br><br>
                                    <v-icon color="green"> mdi-checkbox-blank </v-icon> Answered
                                    <v-icon color="red"> mdi-checkbox-blank </v-icon> Not Answered 
                                </span>
          </v-card>
          </v-col>
        </v-row>
       
        </v-col>
      </v-row>
    <!-- <v-container fluid> -->
     </div>
    
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
        
  data() {
    return {
      snackbar_msg: "",
      color: "",
      snackbar: false,
      search: "",
      tenantid : '',
      questionlist:[],
      feedbacknumber:"",
      islastquestion:false,
      ay:"",
      sem:"",
      startdate:"",
      enddate:"",
      feedbacktype:"",
      questioncount:"",
      feedbackoffering_id:"",
    tenant:"",
    company:"",
    tpoid:"",
       search:'',
       status:"",
       qno:"",
       current_Q_id:"",
       allanswered:"",
    };
  }, // end of data()
  mounted(){
   // alert("mounted");
    this.tenantid = window.location.href;
    const data={
        params : this.tenantid,
    };
       axios.get("TPO/getemployerquestions",data)
    .then(res =>{
      if(res.data.msg=="200"){
      //  console.log(res.data.status);
        this.status=res.data.status
        if(!res.data.status){
        this.questionlist = res.data.questionlist;
        this.ay = res.data.ay;
        this.sem = res.data.semester;
        this.feedbackoffering_id = res.data.feedbackoffering_id;
        this.qno = 0
        this.startdate = res.data.startdate.split("T")[0];
        this.enddate = res.data.enddate.split("T")[0];
        this.feedbacktype = res.data.feedbacktype;
        this.feedbacknumber = res.data.feedbacknumber;
        this.questioncount = res.data.questioncount;
        this.tenant= res.data.tenant;
        this.company = res.data.company.id; 
        this.tpoid = res.data.tpoid;
        this.allanswered=res.data.allanswered;
        }
      }
      
      else{
      }
    })
    .catch(error => {
        window.console.log(error)
    })
  },
  computed: {
    // filteredSortSearchList(){
    //   function compare(a, b) {
    //     if (a.name < b.name) return -1;
    //     if (a.name > b.name) return 1;
    //     return 0;
    //   }
    //   if (!this.search) return this.routerLinkList.sort(compare);
      
    //   return this.routerLinkList.filter(tbdata=>{
    //       return tbdata.name.toLowerCase().indexOf(this.search.toLowerCase())>=0       
    //   })
    // }
  } ,// end of computed()


  methods: {
        save(value) {
         //  alert("save ");

         var count =0;
              // if(value.is_choiceapplicable == true){
              //   if(value.singleanswer ==""){
              //       this.showSnackbar("#b71c1c",  "Please select at least one Option...");
              //         return;
              //   }
              // }
              // if(value.is_multiplechoice == true){
              //    for(var a in value.options){
              //       console.log(value.options[a].isSelectedOP)
              //           if(value.options[a].isSelectedOP == true){
              //           count++;
              //         }
              //       }
              //       console.log(count);
              //       if(count == 0){
              //         this.showSnackbar("#b71c1c", "Please select at least one Option...");
              //         return;
              //       }
              // }
              //  if(value.is_choiceapplicable != true && value.is_multiplechoice != true){
              //       // alert("hiii");
              //       //  console.log(value.descrAnswer);
              //       if(value.descrAnswer == null || value.descrAnswer =='null'){
              //         this.showSnackbar("#b71c1c", "Please Answer the Question..");
              //         return;
              //         }
              //  }
         
            const data = {
                questionlist : value,
                tenant:this.tenant,
                company:this.company,
                tpoid:this.tpoid,
                feedbackoffering_id:this.feedbackoffering_id,
            };
                axios
                    .post("/TPO/savefeedbackofferingquestion", data)
                    .then(res => {
                        if (res.data.msg == "200") {
                          // console.log("hiiiiiiiii")
                          // console.log(res.data.questionlist);
                          
                          this.allanswered = res.data.allanswered;
                            this.questionlist = res.data.questionlist;
                            this.qno++
                            // console.log("queslist length "+this.questionlist.length)
                            // console.log("this.qno  "+this.qno)
                          //  if(this.qno != this.questionlist.length){
                          //      this.changeQuestion(this.qno)
                          //  }
                          if(this.qno == this.questionlist.length){
                              this.qno--;
                          }
                        //   console.log("this.qno newwww  "+this.qno)
                           this.changeQuestion(this.qno)
                           if(this.allanswered == 'YES'){
                                this.showSnackbar("green", "THANK YOU. Complete Feedback Recorded Successfully");
                           }else{
                            this.showSnackbar("green", "Feedback Recorded Successfully...");
                           }
                        }else if (res.data.msg == "500") {
                            this.showSnackbar("#b71c1c", res.data.status); 
                        }else {
                            this.showSnackbar("#b71c1c", res.data.msg); 
                        }
                    })
                    .catch(error => {
                        this.showSnackbar("#b71c1c", "Something Went Wrong...");
                    });
          
            
        },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
         changeQuestion(qno){
          //  alert("changeQuestion");
          //  console.log("changeQuestion "+qno)
          //  console.log("this.questionlist.length "+this.questionlist.length)
          //  this.current_Q_id = question_id;
          if(this.questionlist.length == qno){
            this.islastquestion = true;
              this.qno = qno-1;
          }else{
              this.qno = qno;
          }
            
            this.currentQuestion(this.qno);
            // console.log("after opton")
            // console.log(this.questionlist[qno])
            // for(var i=0;i<this.questionlist.length;i++){
            //    if(this.questionlist[i].question_id == question_id){
            //      this.qno = i;
            //      console.log("qno after "+ this.qno)
            //      this.currentQuestion(this.qno);
            //      break;
            //    }
            // }
        },
         currentQuestion(qno){
        //  alert("hiii");
            var butn = document.getElementsByClassName("butn");
         //   alert(butn.length);
            for(var i=0;i<butn.length;i++){
                if(i == qno){
                   butn[i].style.fontWeight = 900;
                   butn[i].style.fontSize = '23px';
                }else{
                   butn[i].style.fontWeight = 500;
                   butn[i].style.fontSize = '15px';
                }
            }
        },

          markedanswer(n,qno){
          // console.log("marke answer"+qno);
          // console.log("choice ");
          // console.log(this.questionlist[qno].is_choiceapplicable);
          // console.log("multi ");
          // console.log(this.questionlist[qno].is_multiplechoice);
          if(this.questionlist[qno].is_choiceapplicable == true){
            for(var a = 0; a < this.questionlist[qno].options.length; a++){
              if(this.questionlist[qno].options[a].option_id == n.option_id){
                // console.log(this.questionlist[qno].options[a].isSelectedOP)
                  this.questionlist[qno].options[a].isSelectedOP = true;
                  this.questionlist[qno].singleanswer = n;
                  // console.log(this.questionlist[qno].options[a].isSelectedOP);
                  // console.log(this.questionlist[qno].singleanswer);
              }
            }
            this.questionlist[qno].feedback_present ='YES'
          }
          if(this.questionlist[qno].is_choiceapplicable != true && this.questionlist[qno].is_multiplechoice != true){
            // console.log("--------------descriptive")
            this.questionlist[qno].feedback_present ='YES'
          }
        }
  }

};
</script>
<style scoped>

.bordr{
  border: 1px solid black;
}
.card-title{
font-weight: 400;
color: #9E9E9E;
}
.add-plus-icon{
        color: #616161
}
.avatar-icon {
  border: 1px solid #0000001a;
}
.link-icon {
  color: #bdbdbd;
}

#card-hover {
  transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
#card-hover:hover {
  background: #ededed;
}
#card-hover:hover .link-icon {
  color: #3f51b5;
}
#card-hover:hover .avatar-icon {
  border-color: #3f51b5;
  background: white;
}
#card-hover:hover .card-title {
   color: #777777;
   
}
#card-hover:hover .add-plus-icon {
  
   border: none;
   border-radius: 100%; 
   color: white;
   background:#3f51b5;
}
.link-icon {
        color: rgb(19, 74, 192);
    }
    .btn-title{
        text-transform: uppercase;
      background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
td,th{
  text-align:center;
  height: 40px;
}
.blink {
            animation: blinker 0.77s linear infinite;
            color: red;
            font-family: sans-serif;
        }
        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
</style>